import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { baseUrl } from '../../../config/baseUrl';
import { toast } from 'react-toastify';
import chemicalbg from "../../../assets/images/chemical-bg.png";
const AddSubCatgory = ({setApiCall,setLoader}) => {
    const [catList, setCatList] = useState([]);
    const [depoList, setDepoList] = useState([]);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [values, setValues] = useState({
        depo: [],
        subcategory_name: "",
    })
    console.log(values)
    const [pdfFile, setPdfFile] = useState(null)
    const [imgFile, setImgFile] = useState(null)
    
    const handleChange = (e,depoQ) => {
        const { name, value } = e.target;
        if (name === "depo") {
            return setValues((curval) => {
                let id = parseInt(value);
                // console.log(curval.depo.includes(id))s
                if (curval.depo.some(item => item.depo === id)) {
                    let data = curval.depo.filter((item) => item.depo !== id);
                    // console.log(data);
                    return { ...curval, [name]: data };
                }
                return {
                    ...curval,
                    [name]: [...curval.depo, {"depo":parseInt(value),"q":""}],
                };
            });
        }else if(depoQ){
            return setValues((curval) => {
                let id=parseInt(name.split("-").pop())
                if (curval.depo.some(item => item.depo === id)) {
                    let data = curval.depo.map((item) => {
                    if(item.depo == id){
                        item.q=value
                    } 
                    return item
                    });
                    // console.log(data);
                    return { ...curval, depo:  data  };
                }
                return {
                    ...curval,
                    [name]: [...curval.depo , {"depo":parseInt(value),"q":""}],
                };
                
            });
            // let id=name.split("-").pop()
            // if (curval.depo.includes(id)) {
            //     console.log("enter");
                
            //     // let data = curval.depo.filter((item) => item !== id);
            //     // console.log(data);
                
            //     // return { ...curval, [name]: data };
            // }
            

        }
         else {
            setValues(prevValues => ({
                ...prevValues,
                [name]: value
            }));
        }
    }
   


    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("inner", values)
        
        if ( !values.subcategory_name) {
            toast.error('Chemical Name required');
            return;
        }
        if (!pdfFile) {
           return toast.error("pdfFile required")
        }
        if (!imgFile) {
            return toast.error("image required")
        }
        if (!values?.depo.length) {
            return  toast.error("depo is required")
        }

      for (let index = 0; index < values?.depo.length; index++) {
        const element = values?.depo[index];
        if(!element.depo){
            return  toast.error("Please select depo")
        }
        if(!element.q){
            return  toast.error("Please enter depo quantity")
        }
        
      }
        try {
            const formData = new FormData();
            formData.append('depo', JSON.stringify(values.depo))
            formData.append('subcategory_name', values.subcategory_name)
            
            if (pdfFile) {
                formData.append('subcategory_document', pdfFile)
            }
            if (imgFile) {
                formData.append('subcategory_images', imgFile)
            }

            let unique = document.getElementById("unique");
            unique.click();

            setLoader(true)
            const res = await axios.post(`${baseUrl}/module/admin/subcategory-viewset`, formData, {
                headers: {
                    Authorization: "Token " + localStorage.getItem("token"),
                },
            })
            if (res.data.code === 200) {
                toast.success(res.data.message)
                setApiCall(Math.random());
                setValues({
                    depo: [],
                    subcategory_name: "",
                });
            } else {
                setApiCall(Math.random());
                toast.error(res.data.message)
            }
        } catch (error) {
            toast.error(error.response)
            console.log("error role permission", error)
        }
    }

    //fetch category 
    const fetchDepo = async () => {
        try {
            const res = await axios.get(`${baseUrl}/module/admin/depo-list`, {
                headers: {
                    Authorization: "Token " + localStorage.getItem("token"),
                },
            })
            // console.log("depo", res.data.data)
            setDepoList(res.data.data)
        } catch (error) {
            console.log("fetch depo error", error)
        }
    }
    //fetch category 
    // const fetchCategory = async () => {
    //     try {
    //         const res = await axios.get(`${baseUrl}/module/admin/category-viewset`, {
    //             headers: {
    //                 Authorization: "Token " + localStorage.getItem("token"),
    //             },
    //         })
    //         // console.log("category", res.data.data)
    //         setCatList(res.data.data)
    //         setValues({...values,category:res?.data?.data[0].id})
    //     } catch (error) {
    //         console.log("fetch category error", error)
    //     }
    // }

    useEffect(() => {
        fetchDepo();
        // fetchCategory();
    }, [])


    return (
        <>
            <div
                className="modal fade custom-modal addbus-modal"
                id="addbus2"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-fullscreen">
                    <div className="modal-content">
                        <div className="modal-header justify-content-center">
                            <h5 className="modal-title" id="">
                                Add Chemical 
                            </h5>
                            <button
                                type="button"
                                className="modal-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                id="unique"
                            >
                                <i className="fa fa-times" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row justify-content-center align-items-center">
                                <div className="col-lg-5 text-center">
                                    <div className="addbus-modal-innr">
                                        <img src={chemicalbg} alt="" />
                                        <form action="" onSubmit={handleSubmit}>
                                            <div className="row justify-content-center">
                                                <div className="frm-bx mb-4 col-lg-8 mb-3 text-start">
                                                    <div className="dropdown ">
                                                        <button
                                                            className="dropdown-toggle"
                                                            type="button"
                                                            id="tablemenu"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                        >
                                                            select depo{" "}
                                                        </button>
                                                        <ul
                                                            className="dropdown-menu"
                                                            aria-labelledby="tablemenu"
                                                        >
                                                            {depoList?.map((arr,i) => (
                                                                <li key={arr?.id} className="dropdown-item">
                                                                    <div className="form-check">
                                                                        <input
                                                                            className="form-check-input checkbox"
                                                                            type="checkbox"
                                                                            defaultValue=""
                                                                            id="check-04"
                                                                            name="depo"
                                                                            checked={
                                                                                values?.depo?.some(item => item?.depo === arr?.id) &&
                                                                                true
                                                                            }
                                                                            value={arr.id}
                                                                            onChange={handleChange}
                                                                        />
                                                                        <label htmlFor="check-04">
                                                                            {arr?.depo_name} 
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-input-box">
                                                                        <input
                                                                            className="form-input"
                                                                            type="number"
                                                                            defaultValue=""
                                                                            name={`q-${arr?.id}`}
                                                                            value={values?.depo?.some(item => item?.depo == arr?.id) && values?.depo[i]?.q }
                                                                            onChange={(e)=>handleChange(e,"depoQ")}
                                                                        />
                                                                        
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>

                                                {/* <div className="frm-bx mb-4 col-lg-8 mb-3 text-start">
                                                    <select
                                                        name="depo"
                                                        onChange={handleChange}
                                                        className="form-select"
                                                        value={values.depo}
                                                        multiple
                                                    >
                                                        <option value="">Select depo </option>
                                                        {depoList.map((arr) => {
                                                            return (
                                                                <option key={arr?.id} value={arr?.id}>
                                                                    {arr.depo_name}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                    <span className="fa fa-building icon" />
                                                </div> */}
                                                {/* <div className="frm-bx mb-4 col-lg-8 text-start">
                                                    <select
                                                    disabled
                                                        name="category"
                                                        onChange={handleChange}
                                                        className="form-select"
                                                        value={values.category}
                                                    >
                                                        {catList?.map((arr) => {
                                                            return (
                                                                <option key={arr?.id} value={arr?.id}>
                                                                    {arr.category_name}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                    <span className="fa fa-building icon" />
                                                </div> */}
                                                <div className="frm-bx mb-4 col-lg-8 text-start">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Chemical Name"
                                                        name="subcategory_name"
                                                        value={values?.subcategory_name}
                                                        onChange={handleChange}
                                                    />

                                                    <span className="fa fa-server icon" />
                                                </div>
                                                {/* <div className="frm-bx mb-4 col-lg-8 text-start">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter description"
                                                        name="desc"
                                                        value={values?.desc}
                                                        onChange={handleChange}
                                                    />
                                                    <span className="fa fa-server icon" />
                                                </div> */}
                                                <div className="frm-bx mb-4 col-lg-8 text-start upload-bx">
                                                        <label>Add PDF</label>
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        // name="subcategory_document"
                                                        accept=".pdf,.doc,.docx"
                                                        onChange={(e) => setPdfFile(e.target.files[0])}
                                                    />
                                                    {/* <span className="fa fa-file icon" /> */}
                                                </div>
                                                <div className="frm-bx mb-4 col-lg-8 text-start upload-bx">
                                                        <label>image</label>
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        accept="image/png"
                                                        onChange={(e) => setImgFile(e.target.files[0])}
                                                    />
                                                    {/* <span className="fa fa-file icon" /> */}
                                                </div>
                                                <div className="frm-bx mb-4 col-lg-8 mb-3">
                                                    <button className="thm-btn" type="submit">
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddSubCatgory