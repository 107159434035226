import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/css/dashboard.css";
import "./assets/css/dashboard-responsive.css";
import "font-awesome/css/font-awesome.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "./Layout";

import PageNotFound from "./Component/404/PageNotFound";

import Protected from "./Protected";
import Dashboard from "./Component/Home/Dashboard";
import Login from "./Component/Login/Login";
import ForgetPassword from "./Component/Login/ForgetPassword";
import UserList from "./Component/Users/UserList";
import UserEdit from "./Component/Users/UserEdit";
import Barcode from "./Component/Barcode/Barcode";
import BusList from "./Component/Bus/BusList";
import JobSchedule from "./Component/Rostering/JobSchedule";
import CompanyList from "./Component/Company/CompanyList";
import DepoList from "./Component/Depo/DepoList";
import CategoryList from "./Component/Category/CategoryList";
import SubCategoryList from "./Component/Subcategory/SubCategoryList";
import UserView from "./Component/Users/UserView";
import ReportList from "./Component/Report/ReportList";
import ReportView from "./Component/Report/ReportView";
import AssignTask from "./Component/Rostering/Assign_task";
import JobScheduleForm from "./Component/Rostering/JobScheduleForm";
import JobScheduleEdit from "./Component/Rostering/JobScheduleEdit";
import ChangeScheduling from "./Component/Rostering/ChangeScheduling";
import DepoListForChangeScheduling from "./Component/Rostering/DepoListForChangeScheduling";
import Demo from "./Component/Bus/Demo";
import ResetPassword from "./Component/Login/ResetPassword";
import PrivacyPolicy from "./Component/PrivacyPolicy";


// v---
import Chemicalcds from "./Component/ChemicalCds/ChemicalHome/Chemicalcds";
import AddCategory from "./Component/ChemicalCds/Category  not working/AddCategory";
import AddSubCatgory from "./Component/ChemicalCds/SubCategory/AddSubCatgory";
import ChemicalCategoryList from "./Component/ChemicalCds/Category  not working/ChemicalCategoryList";
import ChemiclSubCatList from "./Component/ChemicalCds/SubCategory/ChemiclSubCatList";
// import ForgetPassword from "./"

import HealthViewsetList from "./Component/healthViewset/HealthViewsetList";
import HealthViewsetAdd from "./Component/healthViewset/HealthViewsetAdd";
import HealthViewsetEdit from "./Component/healthViewset/HealthViewsetEdit";
import HealthPolicyPage from "./Component/healthViewset/HealthPolicyPage";

import HealthSafetyDocumentList from "./Component/ChemicalCds/HealthSafetyDocument/HealthSafetyDocumentList"

function App() {
  let token = localStorage.getItem("token");
  return (
    <>
      <BrowserRouter>
        <Routes>
        
          <Route path="/Login" element={ <Login/>} />
          <Route path="/forget-password" element={ <ForgetPassword/>} />
          <Route path="/reset-password" element={ <ResetPassword/>} />
          
         <Route path="/" element={< Layout />}>
            <Route index element={<Protected Component={Dashboard} />} />
            <Route
              path="user-list"
              element={<Protected Component={UserList} />}
            />
            <Route
              path="User-edit/:modelType/:id"
              element={<Protected Component={UserEdit} />}
            />
            <Route
              path="User-view/:id"
              element={<Protected Component={UserView} />}
            />
            <Route path="barcode" element={<Protected Component={Barcode} />} />
            <Route
              path="bus-list"
              element={<Protected Component={BusList} />}
            />
            <Route
              path="job-scheduled"
              element={<Protected Component={JobSchedule} />}
            />
            <Route
              path="job-schedule"
              element={<Protected Component={AssignTask} />}
            />
            <Route
              path="/job-schedule-form"
              element={<Protected Component={JobScheduleForm} />}
            />
            <Route
              path="/job-schedule-edit/:id"
              element={<Protected Component={JobScheduleEdit} />}
            />
            <Route
              path="company-list"
              element={<Protected Component={CompanyList} />}
            />
            <Route
              path="depo-list"
              element={<Protected Component={DepoList} />}
            />
            <Route
              path="category-list"
              element={<Protected Component={CategoryList} />}
            />
            <Route
              path="subcategory-list"
              element={<Protected Component={SubCategoryList} />} // comment no working 
            />
            <Route
              path="report-list"
              element={<Protected Component={ReportList} />}
            />
            <Route
              path="report-view/:id"
              element={<Protected Component={ReportView} />}
            />
            <Route
            path="depo-list-for-change-scheduling"
            element={<Protected Component={DepoListForChangeScheduling} />}
          />
            <Route
            path="change-scheduling/:id"
            element={<Protected Component={ChangeScheduling} />}
          />
            <Route
            path="demo"
            element={<Protected Component={Demo} />}
          />
          {/* *************vvv */}
            <Route
            path="chemicalcdslist"
            element={<Protected Component={Chemicalcds} />}
          />
            <Route
            path="chemicalcategorylist"
            element={<Protected Component={ChemicalCategoryList} />}
          />
            <Route
            path="chemicalsubcategorylist"
            element={<Protected Component={ChemiclSubCatList} />}
          />
            <Route
            path="addcategory"
            element={<Protected Component={AddCategory} />}
          />
            <Route
            path="addsubcategory"
            element={<Protected Component={AddSubCatgory} />}
          />
            <Route
            path="health-viewset-list"
            element={<Protected Component={HealthViewsetList} />}
          />
            <Route
            path="health-viewset-add"
            element={<Protected Component={HealthViewsetAdd} />}
          />
            <Route
            path="health-viewset-edit/:id"
            element={<Protected Component={HealthViewsetEdit} />}
          />
            <Route
            path="health-policy"
            element={<Protected Component={HealthPolicyPage} />}
          />
            <Route
            path="health-safety-document-list"
            element={< HealthSafetyDocumentList />}
          />

          </Route>
           <Route
            path="privacy"
            element={<PrivacyPolicy />}
          />
          <Route
            path="/privacy-policy"
            element={<PrivacyPolicy />}
          />
          <Route
            path="*"
            element={<PageNotFound/>}
          />  
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </>
  );
}

export default App;
