import axios from "axios";
import React, { useEffect, useState ,useContext} from "react";
import { Link } from "react-router-dom";
import { baseUrl } from "../../../config/baseUrl";
import AddHealthSafetyDocument from "./AddHealthSafetyDocument";
import EditHealthSafetyDocument from "./EditHealthSafetyDocument";
import DeleteToast from "../../Users/DeleteToast";
import { toast } from "react-toastify";
import loaderContext from "../../../context/LoaderContext";

const HealthSafetyDocumentList = () => {
  const [catList, setCatList] = useState([]);
  const [deleteid, setDeleteid] = useState();
  const [apiData, setAPiData] = useState([]);
    const [apicall, setApicall] = useState(0);
    const [values, setValues] = useState(0);
// let {setLoader}= useContext(loaderContext)
let {loader, setLoader } = useContext(loaderContext);
let user=JSON.parse(localStorage.getItem("user"))
  useEffect(() => {
    setLoader(true)
    fetchApi();
    // setLoader(false)
  }, [apicall]);

  const fetchApi = async () => {
    try {
      const res = await axios.get(
        `${baseUrl}/module/admin/helth-document-viewset`,
        {
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
          },
        }
      );
      // console.log("category", res.data.data);
      setAPiData(res.data.data);
    } catch (error) {
      console.log("fetch category error", error);
    }finally{
      console.log("work finally");
      setLoader(false)
    }
  };

  const handleEdit = async (id) => {
    try {
  
      const res = await axios.get(
        `${baseUrl}/module/admin/helth-document-viewset/${id}`,
        {
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
          },
        }
      );
      setValues(res.data.data)
      if (res?.data?.code === 200) {
        // toast.success(res?.data?.message);
        let unique = document.getElementById("openDilerBox");
        // console.log(unique);
        unique.click(); 
      } else {
        toast.error(res?.data?.message);
      }
      
    } catch (error) {
      console.log("edit cat errro", error);
    }
  };

  const deleteHandle = async (id) => {
    try {
      setLoader(true)
      const res = await axios.delete(
        `${baseUrl}/module/admin/helth-document-viewset/${id}`,
        {
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
          },
        }
      );
    
      if (res?.data?.code === 204) {
        toast.success(res?.data?.message);
        
        // catList.filter((cat) =>cat.id !== res.data.data.id)
        fetchApi();
      } else {
        toast.error(res?.data?.message);
        fetchApi();
      }
    } catch (error) {
      console.log("error delete cat", error);
    }
  };

  return (
    <>
    
    {  loader ? <div className= {`${loader ? "loader-main" : ""}`} >
    <div className={`${loader ? "loader" : ""}`}></div></div>
      : 
      <><section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fa fa-chart-bar me-2" />
                Health Safety Document List
              </h4>
              {user?.user_type == "SUPER_ADMIN" || user?.user_type == "ADMIN_MANAGER" && <Link
                to="#"
                className="blu-btn"
                data-bs-toggle="modal"
                data-bs-target="#addcat"
              >
                <i className="fa fa-plus me-2" />
                Add
              </Link>}
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Health Safety Document List
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards bus-list">
              <div className="bus-filter">
                <div className="row justify-content-end">
                  <div className="col-lg-8">
                    <form action="">
                      <div className="row justify-content-end">
                       
                        {/* <div className="col-lg-4">
                          <select
                            name="depo"
                            // onChange={filterHandler}
                            className="form-select"
                            // value={searchData?.depo}
                          >
                            <option value="">Select Depo</option>
                            {depoData?.map((arr) => (
                              <option key={arr?.id} value={arr?.id}>
                                {arr?.depo_name}
                              </option>
                            ))}
                          </select>
                        </div> */}
                        {/* <div className="col-lg-2">
                          <button
                            className="thm-btn w-100"
                            // onClick={handleSearch}
                          >
                            Submit
                          </button>
                        </div> */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="table table-responsive custom-table">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th>Sr No.</th>
                      <th>name</th>
                      <th>created_at</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {apiData.map((arr, index) => {
                      return (
                        <tr key={arr.id}>
                          <td>
                            <span>{index + 1}</span>
                          </td>
                          <td>
                            <span>{arr?.name}</span>
                          </td>
                          <td>{arr?.created_at.split("T")[0]}</td>
                          <td>
                            <td>
                             
                              <Link
                                className="btn btn-outline-warning"
                                // data-bs-toggle="modal"
                                // data-bs-target="#editHealthDoc"
                                onClick={() => handleEdit(arr?.id)}
                              >
                                <i className="fa fa-edit" />
                              </Link>
                              <button data-bs-toggle="modal"
                                data-bs-target="#editHealthDoc" id="openDilerBox" style={{display:"none"}}></button>
                             
                              &nbsp;
                              <button
                                className="btn btn-outline-danger"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#pupup"
                                onClick={() => setDeleteid(arr?.id)}
                              >
                                <i className="fa fa-trash" />
                              </button>
                            </td>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                {/* <Pagination
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                  pageLimit={pageLimit}
                  paginationDetails={paginationDetails}
                /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <AddHealthSafetyDocument  setApicall={setApicall} setLoader={setLoader}/>
      <EditHealthSafetyDocument setValues={setValues} setApicall={setApicall} values={values}  setLoader={setLoader} />
      <DeleteToast deleteHandle={deleteHandle} id={deleteid} /></>
    }</> 
  );
};

export default HealthSafetyDocumentList;
