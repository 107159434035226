import React, { useEffect, useState,useContext } from "react";
import SubCategoryEdit from "./HealthViewsetEdit";
import SubCategoryAdd from "./HealthViewsetAdd";
import axios from "axios";
import { toast, Bounce } from "react-toastify";
import { baseUrl } from "../../config/baseUrl";
import { json, Link } from "react-router-dom";
import DeleteToast from "../Users/DeleteToast";
import loaderContext from "../../context/LoaderContext";

export default function HealthViewsetList() {
  const [data, setData] = useState([]);
  const [editSubCategory, setEditSubCategory] = useState(null);
 let [deleteid, setDeleteid] = useState();
  // Fetch category list
  let user = JSON.parse(localStorage?.getItem("user"))
  let {loader,setLoader}=useContext(loaderContext)
  
  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${baseUrl}/module/admin/health-viewset`,
        {
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
          },
        }
      );
      setData(response.data.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }finally{
      setLoader(false)
    }
  };

  // Handle delete
  const deleteHandle = async (id) => {
    setLoader(true)
      try {
        let res=await axios.delete(
          `${baseUrl}/module/admin/health-viewset/${id}`,
          {
            headers: {
              Authorization: "Token " + localStorage.getItem("token"),
            },
          }
        );
        fetchCategories();
        if (res?.data?.code === 204) {
                toast.success(res?.data?.message);
                // catList.filter((cat) =>cat.id !== res.data.data.id)
                fetchCategories();
              } else {
                toast.error(res?.data?.message);
                fetchCategories();
              }
      } catch (error) {
        console.error("Error deleting category:", error);
         
      }
    
  };

  // Handle edit
 

  // Refresh categories after add/edit
  const handleRefresh = () => {
    fetchCategories();
  };

  useEffect(() => {
    setLoader(true)
    fetchCategories();
  }, []);

  return (
    <>{loader ? (
      <div className={`${loader ? "loader-main" : ""}`}>
        <div className={`${loader ? "loader" : ""}`}></div>
      </div>
    ) : (
      <> <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fa fa-chart-bar me-2" />
                Health & Safety Management List 
              </h4>
              {/* <button
                className="blu-btn"
                data-bs-toggle="modal"
                data-bs-target="#addsubcategory"
              >
                <i className="fa fa-plus me-2" />
                Add
              </button> */}
              {data?.length == 0 || user?.user_type === "SUPER_ADMIN" &&   <Link
                to="/health-viewset-add" 
                className="blu-btn"               
              >
                <i className="fa fa-plus me-2" />
                Health & Safety Add
              </Link>}
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                  Health & Safety Management
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards bus-list">
              <div className="table table-responsive custom-table">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th>Sr No.</th>
                      <th>company Name</th>
                      <th>created_at</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((arr, index) => (
                      <tr key={arr.id}>
                        <td>{index + 1}</td>
                        <td>{arr.company?.company_name}</td>
                        {/* <td>{arr.policy}</td> */}
                        <td>{arr.created_at.split("T")[0]}</td>
                        {/* <td>{arr.category.category_name}</td> */}
                        <td>
                          <Link
                            className="btn btn-outline-warning"
                            // data-bs-toggle="modal"
                            // data-bs-target="#editarr"

                            to={`/health-viewset-edit/${arr?.id}`}
                            // onClick={() => setEditarr(arr)}
                          >
                            <i className="fa fa-edit" />
                          </Link>
                          &nbsp;
                          {/* <button
                            className="btn btn-outline-danger"
                            onClick={() => handleDelete(arr.id)}
                          >
                            <i className="fa fa-trash" />
                          </button> */}
                          <button
                              className="btn btn-outline-danger"
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#pupup"
                              onClick={() => setDeleteid(arr?.id)}
                            >
                              <i className="fa fa-trash" />
                            </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Add Modal */}
      {/* <SubCategoryAdd onRefresh={handleRefresh} /> */}

      {/* Edit Modal */}
      
        {/* <SubCategoryEdit
          subcategory={editSubCategory}
          onRefresh={handleRefresh}
          onClose={() => setEditSubCategory(null)}
        /> */}
      <DeleteToast deleteHandle={deleteHandle} id={deleteid} />;
    </>)}
    
    </>
  );
}
