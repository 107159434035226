import React, { useEffect, useState } from "react";
import SubCategoryEdit from "./SubCategoryEdit";
import SubCategoryAdd from "./SubCategoryAdd";
import axios from "axios";
import { toast, Bounce } from "react-toastify";
import { baseUrl } from "../../config/baseUrl";

export default function CategoryList() {
  const [categories, setCategories] = useState([]);
  const [editSubCategory, setEditSubCategory] = useState(null);

  // Fetch category list
  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${baseUrl}/module/admin/health-viewset`,
        {
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
          },
        }
      );
      setCategories(response.data.data?.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  // Handle delete
  const handleDelete = async (subcategoryId) => {
    if (window.confirm("Are you sure you want to delete this category?")) {
      try {
        await axios.delete(
          `http://127.0.0.1:8000/subcategory-viewset/${subcategoryId}`
        );
        alert("Category deleted successfully!");
        fetchCategories();
      } catch (error) {
        console.error("Error deleting category:", error);
        alert("Failed to delete category.");
      }
    }
  };

  // Handle edit
 

  // Refresh categories after add/edit
  const handleRefresh = () => {
    fetchCategories();
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fa fa-chart-bar me-2" />
                SubCategory List
              </h4>
              <button
                className="blu-btn"
                data-bs-toggle="modal"
                data-bs-target="#addsubcategory"
              >
                <i className="fa fa-plus me-2" />
                Add
              </button>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Dashboard</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                  SubCategory List
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards bus-list">
              <div className="table table-responsive custom-table">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th>Sr No.</th>
                      <th>SubCategory Name</th>
                      <th>Category Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {categories?.map((arr, index) => (
                      <tr key={arr.id}>
                        <td>{index + 1}</td>
                        <td>{arr.arr_name}</td>
                        <td>{arr.category.category_name}</td>
                        {/* <td>
                          <button
                            className="btn btn-outline-warning"
                            data-bs-toggle="modal"
                            data-bs-target="#editarr"
                            onClick={() => setEditarr(arr)}
                          >
                            <i className="fa fa-edit" />
                          </button>
                          &nbsp;
                          <button
                            className="btn btn-outline-danger"
                            onClick={() => handleDelete(subcategory.id)}
                          >
                            <i className="fa fa-trash" />
                          </button>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Add Modal */}
      {/* <SubCategoryAdd onRefresh={handleRefresh} /> */}

      {/* Edit Modal */}
      
        {/* <SubCategoryEdit
          subcategory={editSubCategory}
          onRefresh={handleRefresh}
          onClose={() => setEditSubCategory(null)}
        /> */}
      
    </>
  );
}
