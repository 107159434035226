import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { baseUrl } from '../../../config/baseUrl';
import AddCategory from './AddCategory';
import EditChemicalCat from './EditChemicalCat';
import DeleteToast from '../../Users/DeleteToast';
import { toast } from 'react-toastify';

const ChemicalCategoryList = () => {
    const [catList, setCatList] = useState([]);
    const [deleteid, setDeleteid] = useState();
    const [values, setValues] = useState({
        category_name: "",
        desc: "",
    })
    useEffect(() => {
        fetchCategory();
    }, [])
    // fetch cat api
    const fetchCategory = async () => {
        try {
            const res = await axios.get(`${baseUrl}/module/admin/category-viewset`, {
                headers: {
                    Authorization: "Token " + localStorage.getItem("token"),
                },
            })
            console.log("category", res.data.data)
            setCatList(res.data.data)
        } catch (error) {
            console.log("fetch category error", error)
        }
    }


    const handleCatEdit = async (id) => {
        try {
            const res = await axios.get(`${baseUrl}/module/admin/category-viewset/${id}`, {
                headers: {
                    Authorization: "Token " + localStorage.getItem("token"),
                },
            })
            setValues(res.data.data)
        } catch (error) {
            console.log("edit cat errro", error)
        }
    }

    const deleteHandle = async (id) => {
        try {
            const res = await axios.delete(`${baseUrl}//module/admin/category-viewset/${id}`, {
                headers: {
                    Authorization: "Token " + localStorage.getItem("token"),
                },
            })
            if (res?.data?.code === 200) {
                toast.success(res?.data?.message);
                catList.filter((cat) =>cat.id !== res.data.data.id)
                fetchCategory();
            } else {
                fetchCategory();
                toast.error(res?.data?.message);
            }
        } catch (error) {
            console.log("error delete cat", error)
        }
    }
    return (
        <>
            <section className="main-sec">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="dashboard-title">
                            <h4 className="dash-head">
                                <i className="fa fa-chart-bar me-2" />
                                Category List  
                            </h4>
                            <Link
                                to="#"
                                className="blu-btn"
                                data-bs-toggle="modal"
                                data-bs-target="#addcat"
                            >
                                <i className="fa fa-plus me-2" />
                                Add
                            </Link>
                        </div>
                        <div className="custom-bredcump">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Category List
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="cards bus-list">
                            <div className="bus-filter">
                                <div className="row justify-content-end">
                                    <div className="col-lg-8">
                                        <form action="">
                                            <div className="row justify-content-end">
                                                {/* {loginUser.user_type === "SUPER_ADMIN" && (
                          <div className="col-lg-4">
                            <select
                              name="company"
                              id=""
                              onChange={filterHandler}
                              className="form-select"
                              value={searchData.company}
                            >
                              <option value="">Select Company</option>
                              {companyNameData?.map((arr) => (
                                <option value={arr?.id} key={arr?.id}>
                                  {arr?.company_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        )} */}
                                                <div className="col-lg-4">
                                                    <select
                                                        name="depo"
                                                        // onChange={filterHandler}
                                                        className="form-select"
                                                    // value={searchData?.depo}
                                                    >
                                                        <option value="">Select Depo</option>
                                                        {/* {depoData?.map((arr) => (
                              <option key={arr?.id} value={arr?.id}>
                                {arr?.depo_name}
                              </option>
                            ))} */}
                                                    </select>
                                                </div>
                                                <div className="col-lg-2">
                                                    <button
                                                        className="thm-btn w-100"
                                                    // onClick={handleSearch}
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="table table-responsive custom-table">
                                <table className="table table-borderless">
                                    <thead>
                                        <tr>
                                            <th>
                                                Sr No.
                                            </th>
                                            <th>category name</th>
                                            <th>description</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            catList.map((cat, index) => {
                                                return (
                                                    <tr key={cat.id}>
                                                        <td><span>{index + 1}</span></td>
                                                        <td><span>{cat?.category_name}</span></td>
                                                        <td><span>{ cat?.desc?.slice(0, 35)}...</span></td>
                                                        <td>
                                                            {/* {(loginUser.user_type === "ADMIN_MANAGER" || loginUser?.user_type === "CLEANING_MANAGER") && ( */}
                                                            <Link
                                                                className="btn btn-outline-warning"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#editcat"
                                                                onClick={() => handleCatEdit(cat?.id)}
                                                            >
                                                                <i className="fa fa-edit" />
                                                            </Link>
                                                            {/* )} */}
                                                            &nbsp;
                                                            <button
                                                                className="btn btn-outline-danger"
                                                                type="button"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#pupup"
                                                                onClick={() => setDeleteid(cat?.id)}
                                                            >
                                                                <i className="fa fa-trash" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>

                                {/* <Pagination
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                  pageLimit={pageLimit}
                  paginationDetails={paginationDetails}
                /> */}

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <AddCategory />
            <EditChemicalCat values={values} setValues={setValues} />
            <DeleteToast deleteHandle={deleteHandle} id={deleteid} />;

        </>
    )
}

export default ChemicalCategoryList