import axios from 'axios';
import React, { useState } from 'react'
import { baseUrl } from '../../../config/baseUrl';
import { toast } from 'react-toastify';
import chemicalbg from "../../../assets/images/chemical-bg.png";
import { useNavigate } from 'react-router-dom';

const EditChemicalCat = ({ values, setValues }) => {


    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    }
    const navigate = useNavigate();
    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log("inner", values)
        if (!values.category_name || !values.desc) {
            toast.error('All Field required');
            return;
        }
        try {
            const res = await axios.put(`${baseUrl}/module/admin/category-viewset/${values?.id}`, values, {
                headers: {
                    Authorization: "Token " + localStorage.getItem("token"),
                },
            })
            console.log(res)

            if (res.data.code === 201) {
                toast.success("category edited successfully")
                let unique = document.getElementById("unique1");
                unique.click();
                navigate('/chemicalcategorylist')
                setValues({
                    category_name: "",
                    desc: "",
                });
            } else {
                toast.error(res.data.message)
            }
        } catch (error) {
            toast.error(error.response)
            console.log("error role permission", error)
        }
    }
    return (
        <>
            <div
                className="modal fade custom-modal addbus-modal"
                id="editcat"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-fullscreen">
                    <div className="modal-content">
                        <div className="modal-header justify-content-center">
                            <h5 className="modal-title" id="">
                                Edit category
                            </h5>
                            <button
                                type="button"
                                className="modal-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                id="unique1"
                            >
                                <i className="fa fa-times" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row justify-content-center align-items-center">
                                <div className="col-lg-5 text-center">
                                    <div className="addbus-modal-innr">
                                        <img src={chemicalbg} alt="" />
                                        <form action="" onSubmit={handleSubmit}>
                                            <div className="row justify-content-center">
                                                <div className="frm-bx mb-4 col-lg-8 mb-3 text-start">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter category name"
                                                        name="category_name"
                                                        value={values.category_name}
                                                        onChange={handleChange}
                                                    />

                                                    <span className="fa fa-server icon" />
                                                </div>
                                                <div className="frm-bx mb-4 col-lg-8 mb-3 text-start">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter description"
                                                        name="desc"
                                                        value={values.desc}
                                                        onChange={handleChange}
                                                    />

                                                    <span className="fa fa-server icon" />
                                                </div>
                                                <div className="frm-bx mb-4 col-lg-8 mb-3">
                                                    <button className="thm-btn" type="submit">
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditChemicalCat