import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { toast, Bounce } from "react-toastify";
import { baseUrl } from "../../config/baseUrl";
import React_Quill_Editor from "./React_Quill_Editor";

export default function HealthPolicyPage() {
  const [policy, setPolicy] = useState();
  const [policyDocument, setPolicyDocument] = useState();
  useEffect(() => {
    fetchHealth();
  }, []);

  const fetchHealth = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/module/admin/health-viewset`,
        {
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
          },
        }
      );
      // console.log(response.data.data);
      console.log(response.data.data[0].policy);
      setPolicyDocument(
        response.data.data?.policy_document ||
          response.data.data[0].policy_document
      );
      setPolicy(response.data.data?.policy || response.data.data[0].policy);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  return (
    <>
      <section className="main-sec">
        <div className="row">
          <h4 className="mb-3 ms-3 text-center">
            {" "}
            <strong>Health Safety Policy & Document  </strong>{" "}
          </h4>
              <iframe
                src={policyDocument}
                title="Dynamic Iframe"
                width="600"
                height="600"
                className="mb-4"
              ></iframe>
          <div
            className="about-content"
            dangerouslySetInnerHTML={{ __html: policy }}
          />
        </div>
      </section>
    </>
  );
}
