import React, { useEffect, useState } from "react";
import CategoryEdit from "./CategoryEdit";
import CategoryAdd from "./CategoryAdd";
import axios from "axios";

export default function CategoryList() {
  const [categories, setCategories] = useState([]);
  const [editCategory, setEditCategory] = useState(null);

  // Fetch category list
  const fetchCategories = async () => {
    try {
      const response = await axios.get("http://127.0.0.1:8000/category-viewset");
      setCategories(response.data.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  // Handle delete
  const handleDelete = async (categoryId) => {
    if (window.confirm("Are you sure you want to delete this category?")) {
      try {
        await axios.delete(
          `http://127.0.0.1:8000/category-viewset/${categoryId}`
        );
        alert("Category deleted successfully!");
        fetchCategories();
      } catch (error) {
        console.error("Error deleting category:", error);
        alert("Failed to delete category.");
      }
    }
  };

  // Handle edit
 

  // Refresh categories after add/edit
  const handleRefresh = () => {
    fetchCategories();
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fa fa-chart-bar me-2" />
                Category List
              </h4>
              <button
                className="blu-btn"
                data-bs-toggle="modal"
                data-bs-target="#addcategory"
              >
                <i className="fa fa-plus me-2" />
                Add
              </button>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Dashboard</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Category List
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards bus-list">
              <div className="table table-responsive custom-table">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th>Sr No.</th>
                      <th>Category Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {categories.map((category, index) => (
                      <tr key={category.id}>
                        <td>{index + 1}</td>
                        <td>{category.category_name}</td>
                        <td>
                          <button
                            className="btn btn-outline-warning"
                            data-bs-toggle="modal"
                            data-bs-target="#editcategory"
                            onClick={() => setEditCategory(category)}
                          >
                            <i className="fa fa-edit" />
                          </button>
                          &nbsp;
                          <button
                            className="btn btn-outline-danger"
                            onClick={() => handleDelete(category.id)}
                          >
                            <i className="fa fa-trash" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Add Modal */}
      <CategoryAdd onRefresh={handleRefresh} />

      {/* Edit Modal */}
      
        <CategoryEdit
          category={editCategory}
          onRefresh={handleRefresh}
          onClose={() => setEditCategory(null)}
        />
      
    </>
  );
}
