import React, { useState, useEffect ,useContext} from "react";
import busImg from "../../assets/images/bus-img.png";
import axios from "axios";
import { Link,useNavigate, useParams } from "react-router-dom";
import { toast, Bounce } from "react-toastify";
import { baseUrl } from "../../config/baseUrl";
import React_Quill_Editor from "./React_Quill_Editor";
import loaderContext from "../../context/LoaderContext";

export default function HealthViewsetEdit() {
  let navigate = useNavigate();
 let {id}=useParams()
  let [companyNameData, setCompanyNameData] = useState([]);

  let {loader,setLoader}=useContext(loaderContext)

  const [formval, setFormval] = useState({assign_date:"", company :"",cleaning_type :'',policy_document:""});
 
  let [pdf, setPdf] = useState("");
  let changeHandler=(e)=>{
    
    if(e.target.name == "policy_document"){
      let file = e?.target?.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          setPdf(reader?.result);
        };
        reader.readAsDataURL(e?.target?.files[0]);
        return setFormval((currVal) => ({
          ...currVal,
          policy_document :e.target.files[0],
        }));
      }
    }
    setFormval((preVal) => ({ ...preVal, [e.target.name]: e.target.value }));
  }

  let loginUser = JSON.parse(localStorage.getItem("user"));

  const companyName = async () => {
    let response = await axios(`${baseUrl}/module/admin/company-list`, {
      method: "GET",
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });
    setCompanyNameData(response.data?.data);
  };


  useEffect(() => {
    setLoader(true)
    try {
      (async () => {
        let response = await axios(`${baseUrl}/module/admin/health-viewset/${id}`, {
          method: "GET",
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
          }, 
        });
        // console.log(response.data.data)
       setFormval({...response?.data.data,company:response?.data.data?.company?.id})
       setPdf(response?.data.data?.policy_document)
      //  console.log(response?.data.data?.policy_document);
      
      if(response.data.code === 403){
        toast.error(response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        return navigate("/health-viewset-list")
      }
      setLoader(false)
      })();
    } catch (error) {
      console.error("Error :", error.response.data);
    }
    // try {
    //   (async () => {
    //     let response = await axios(`${baseUrl}/module/admin/depo-list`, {
    //       method: "GET",
    //       headers: {
    //         Authorization: "Token " + localStorage.getItem("token"),
    //       }, 
    //     });
    //     console.log(response.data.data)
    //     // setBusData(response?.data?.data);
    //     setdepotData(response?.data?.data)
    //   })();
    // } catch (error) {
    //   console.error("Error :", error.response.data);
    // }
    companyName()
  }, []);

  let handleSubmit =async (e)=>{
    e.preventDefault();
    console.log(formval);
    console.log(typeof formval?.policy_document);
   
    if (!formval.policy_document || !formval.policy || !formval.company) {
      return alert("all fileds is required");
    }
    const formData = new FormData();
   
    formData.append('policy', formval.policy)
    formData.append('company', formval.company) 
if(typeof formval?.policy_document == "object"){
  formData.append('policy_document', formval.policy_document)
}
    try {
      const response = await axios(`${baseUrl}/module/admin/health-viewset/${id}`, {
        method: "PUT",
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
        data: formData,
      });

      if(response.data.code === 201){
        toast.success("health Policy Edited", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        navigate("/health-viewset-list");
      }else{
        toast.error(response?.data?.error_message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }

      // navigate("/BusList")
    } catch (error) {
      console.error("Error :", error);
    }
  }
 


  return (
    <>
   {loader ?   
        <div className={`${loader ? "loader-main" : ""}`}>
          <div className={`${loader ? "loader" : ""}`}></div>
        </div> :
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fa fa-users me-2" />
                Health & Safety Management
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Edit Health & Safety Policy
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards edit-usr">
              <form action="" onSubmit={handleSubmit}>
                <div className="row">
  
                  <div className="col-lg-4 mb-4"> 
                    <label htmlFor="" className="form-label">
                      company
                    </label>
                    <select name="company"  onChange={changeHandler} value={formval?.company}  className="form-select">
                      <option value="">Select company name</option>
                     { companyNameData.map((arr)=>(
                     <option key={arr?.id} value={arr?.id}>{arr?.company_name}</option>  
                      ))}
                    </select>
                  </div>
                  <div className="col-lg-4 mb-3 ">
                    <label htmlFor="" className="form-label">
                    Policy Pdf 
                    </label>
                    <input
                      type="file"
                      accept=".pdf"
                      onChange={changeHandler}
                      name="policy_document"
                      // onChange={(e)=>setPdf(e.target.files[0])}
                      className="form-control"
                    />
                  </div>
                  {pdf && <div className="col-lg-4"><iframe src={pdf}  width="180" height="100" id="pdfFrame"></iframe></div> }
                  
                  <div className="mb-3 col-lg-12">
                    <React_Quill_Editor
                      disabled={false}
                      data={formval?.policy}
                      seteditor={setFormval}
                      fieldName={"policy"}
                      editorTitle={"Policy Details"}
                    />
                  </div>
                  <div className="col-lg-12 text-center">
                    <button                   
                      type="Submit"
                      className="thm-btn">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>}
    </>
  );
}

