import React, { useState } from "react";
import busImg from "../../assets/images/bus-img.png";
import axios from "axios";

export default function CategoryAdd() {
  const [formval, setFormval] = useState({
    category_name: "",
  });

  const ChangeHandler = (e) => {
    setFormval((currVal) => ({
      ...currVal,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formval.category_name) {
      alert("Category name is required!");
      return;
    }

    try {
      const response = await axios.post(
        "http://127.0.0.1:8000/category-viewset",
        { category_name: formval.category_name }
      );

      if (response.status === 201 || response.status === 200) {
        alert("Category added successfully!");
        setFormval({ category_name: "" });
      } else {
        alert("Failed to add category. Please try again.");
      }
    } catch (error) {
      console.error("Error adding category:", error);
      alert("An error occurred while adding the category.");
    }
  };

  return (
    <>
      <div
        className="modal fade custom-modal addbus-modal"
        id="addcategory"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h5 className="modal-title">Add Category</h5>
              <button
                type="button"
                className="modal-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="unique"
              >
                <i className="fa fa-times" />
              </button>
            </div>
            <div className="modal-body">
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-5 text-center">
                  <div className="addbus-modal-innr">
                    <img src={busImg} alt="Bus" />
                    <form onSubmit={handleSubmit}>
                      <div className="row justify-content-center">
                        <div className="frm-bx mb-4 col-lg-8 mb-3 text-start">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Category Name"
                            name="category_name"
                            onChange={ChangeHandler}
                            value={formval.category_name}
                          />
                          <span className="fa fa-server icon" />
                        </div>
                        <div className="frm-bx mb-4 col-lg-8 mb-3">
                          <button className="thm-btn" type="submit">
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
