import React, { useContext } from 'react'
import { Outlet } from 'react-router-dom'
import Header from './Component/layout/Header'
import Sidebar from './Component/layout/Sidebar'
import loaderContext from './context/LoaderContext'
export default function Layout() {
  // let {loader, setLoader } = useContext(loaderContext);
  return (
      
    <> <Header/>
    <Sidebar/>
    <Outlet />  </>
  
  //   <> {  loader ? <div className= {`${loader ? "loader-main" : ""}`} >
  //   <div className={`${loader ? "loader" : ""}`}></div></div>
  //    :  
  //   <> <Header/>
  //   <Sidebar/>
  //   <Outlet /></>
  // }</>
  )
}
