import axios from "axios";
import React, { useEffect, useState } from "react";
import { baseUrl } from "../../../config/baseUrl";
import { toast } from "react-toastify";
import chemicalbg from "../../../assets/images/chemical-bg.png";

const AddHealthSafetyDocument = ({ setApicall, setLoader }) => {
  const [values, setValues] = useState({
    name: "",
    company: "",
  });
  const [catList, setCatList] = useState([]);
  const [pdfFile, setPdfFile] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  useEffect(() => {
    fetchCompnay();
  }, []);

  //   fetch category
  const fetchCompnay = async () => {
    try {
      const res = await axios.get(`${baseUrl}/module/admin/company-list`, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      });
      // console.log("category", res.data.data)
      setCatList(res.data.data);
      setValues({ ...values, category: res?.data?.data[0].id });
    } catch (error) {
      console.log("fetch category error", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("inner", values)

    if (!values.name || !values.company) {
      toast.error("All Field required");
      return;
    }
    if (!pdfFile) {
      return toast.error("document required");
    }

    const formData = new FormData();
    formData.append("name", values.name);
    // formData.append('category', values.category)
    formData.append("company", values.company);

    if (pdfFile) {
      formData.append("helth_document", pdfFile);
    }
    let unique = document.getElementById("uniqueclose");
    unique.click();
    setLoader(true);
    try {
      const res = await axios.post(
        `${baseUrl}/module/admin/helth-document-viewset`,
        formData,
        {
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.code === 200) {
        toast.success(res.data.message);
        setApicall(Math.random());
        setValues({
          name: "",
          company: "",
        });
        // setLoader(false);
      } else {
        setApicall(Math.random());
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.response);
      console.log("error role permission", error);
    }
  };
  return (
    <>
      <div
        className="modal fade custom-modal addbus-modal"
        id="addcat"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h5 className="modal-title" id="">
                Add Health Safety Document
              </h5>
              <button
                type="button"
                className="modal-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="uniqueclose"
              >
                <i className="fa fa-times" />
              </button>
            </div>
            <div className="modal-body">
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-5 text-center">
                  <div className="addbus-modal-innr">
                    <img src={chemicalbg} alt="" />
                    <form action="" onSubmit={handleSubmit}>
                      <div className="row justify-content-center">
                        <div className="frm-bx mb-4 col-lg-8 text-start">
                          <select
                            name="company"
                            onChange={handleChange}
                            className="form-select"
                            value={values.company}
                          >
                            <option value="">select Company</option>
                            {catList?.map((arr) => {
                              return (
                                <option key={arr?.id} value={arr?.id}>
                                  {arr.company_name}
                                </option>
                              );
                            })}
                          </select>
                          <span className="fa fa-building icon" />
                        </div>
                        <div className="frm-bx mb-4 col-lg-8 mb-3 text-start">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Health Safety Document Name "
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                          />

                          <span className="fa fa-server icon" />
                        </div>

                        <div className="frm-bx mb-4 col-lg-8 text-start upload-bx">
                          <label>Add Health document</label>
                          <input
                            type="file"
                            className="form-control"
                            accept=".pdf,.doc,.docx"
                            onChange={(e) => setPdfFile(e.target.files[0])}
                          />
                        </div>
                        <div className="frm-bx mb-4 col-lg-8 mb-3">
                          <button className="thm-btn" type="submit">
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddHealthSafetyDocument;
