import React, { useState, useEffect } from "react";
import axios from "axios";

export default function CategoryEdit({ category, onRefresh, onClose }) {
  const [categoryName, setCategoryName] = useState("");

  // Update state when the category prop changes
  useEffect(() => {
    if (category) {
      setCategoryName(category.category_name);
    }
  }, [category]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(
        `http://127.0.0.1:8000/category-viewset/${category.id}`,
        { category_name: categoryName }
      );
      alert("Category updated successfully!");
      onRefresh();
      onClose();
    } catch (error) {
      console.error("Error updating category:", error);
      alert("Failed to update category.");
    }
  };

  return (
    <div
      className="modal fade custom-modal addbus-modal"
      id="editcategory"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-fullscreen">
        <div className="modal-content">
          <div className="modal-header justify-content-center">
            <h5 className="modal-title">Edit Category</h5>
            <button
              type="button"
              className="modal-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
            >
              <i className="fa fa-times" />
            </button>
          </div>
          <div className="modal-body">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-5 text-center">
                <div className="addbus-modal-innr">
                  <form onSubmit={handleSubmit}>
                    <div className="row justify-content-center">
                      <div className="frm-bx mb-4 col-lg-8 mb-3 text-start">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Category Name"
                          value={categoryName}
                          onChange={(e) => setCategoryName(e.target.value)}
                        />
                        <span className="fa fa-server icon" />
                      </div>
                      <div className="frm-bx mb-4 col-lg-8 mb-3">
                        <button className="thm-btn" type="submit">
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
